@media only screen and (max-width: 480px ) {
    .footer{
        position: absolute;
        bottom: 0;
        width: 100% !important; 
    }
    
    .content ul {
        display: flex;
        justify-content: center;
        list-style: none;
        margin: auto;
        padding: 10px;
    }
    .content ul li {
       margin: 0 7px;
    }
    .btn-BuysBomb{
        border-radius: 27px;
        filter: drop-shadow(0px 3px 3px rgba(0,0,0,0.2 ));
        background: #71ff71;
        font-family: "poppins";
        font-size: 12px;
        font-weight: 400;
        line-height: 12px;
        font-style: normal;
        color: #081606;
        width: 139px;
        height: 27px;
    }
  }