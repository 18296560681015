/* -------------- Max-width: 320px (smaller phone viewpoints) --------------*/

@media only screen and (min-width: 320px ) {
    .title{
        font-size: 30px;
        text-shadow: 3px 0 #081606;
        letter-spacing:3px;
        }
        .dashboard h1 {
            margin-bottom: -30px;
            margin-left: 2px;
        }
        .section-one{
        min-width: 290px;
        padding: 0 10px;
        margin-top: 20px;
        }
        .sub-title{
        font-size: 12px;
        line-height: 10px;
        margin-top: 22px;
        }
        .sub-title-r{
            margin-top: 30px !important;
        }
        .small-text{
        font-size: 8px;
        font-weight: normal;
        line-height: 16px;
        margin-top: -10px;
        }
        .small-text-three{
        font-size: 12px;
        font-weight: 400;
        line-height: 36px;
        margin: 0;
        padding: 10px 0;
        }
  }

/* -------------- Max-width: 480px (small devices and most phones) --------------*/  

  @media only screen and (min-width: 480px ) {
    .title{
        font-size: 30px;
        text-shadow: 2.5px 0 #081606;
letter-spacing:3px;
        }
        .dashboard h1 {
            margin-bottom: -30px;
            margin-left: 2px;
        }
        .section-one{
        min-width: 350px;
        padding: 0 10px;
        margin-top: 20px;
        }
        .sub-title{
        font-size: 12px;
        font-weight: 400;
        line-height: 10px;
        margin-top: 22px;
        }
        .sub-title-r{
            margin-top: 30px !important;
        }
        .small-text{
        font-size: 8px;
        font-weight: normal;
        line-height: 16px;
        margin-top: -10px;
        }
        .small-text-three{
        font-size: 12px;
        font-weight: 400;
        line-height: 25px;
        margin: 0;
        padding: 12px 0;
        }
  }  

/* -------------- Max-width: 768px (most tablets) --------------*/  

@media only screen and (min-width: 600px)  {
    .title{
        font-size: 35px;
        }
        .dashboard h1 {
            margin-bottom: -20px;
            margin-left: 2px;
        }
        .section-one{
        min-width: 480px;
        padding: 10px 40px;
        margin-top: 30px;
        }
        .sub-title{
        font-size: 25px;
        font-weight: 400;
        line-height: 10px;
        margin-top: 22px;
        }
        .sub-title-r{
            margin-top: 25px !important;
        }
        .small-text{
        font-size: 12px;
        font-weight: normal;
        line-height: 16px;
        margin-top: -10px;
        }
        .small-text-three{
        font-size: 16px;
        font-weight: 400;
        line-height: 35px;
        margin: 0;
        padding: 12px 0;
        }
  }    

  @media only screen and (min-width: 900px)   {
    .title{
        font-size: 65px;
        line-height: 90px !important;
        }
        .section-one{
        min-width: 808px;
        padding: 10px 60px;
        margin-top: 40px;
        }
        .sub-title{
        font-size: 35px;   
        line-height: 35px;
        margin-top: 13px;
        }
        .small-text{
        font-size: 16px;
        line-height: 16px;
        margin-top: -10px;
        }
        .small-text-three{
        font-size: 16px;
        margin: 12px -30px 8px -30px;
        }
  }  
  
 @media (max-height: 900px) and (min-width: 900px) {
    .title{
        font-size: 65px;
        line-height: 30px;
        margin-top: 20px;
        }
        .section-one{
        min-width: 808px;
        padding: 0 40px;
        margin-top: 30px;
        }
        .sub-title{
        font-size: 35px;   
        line-height: 35px;
        margin-top: 13px;
        }
        .small-text{
        font-size: 16px;
        line-height: 16px;
        margin-top: -10px;
        }
        .small-text-three{
        font-size: 16px;
        margin: 12px -30px 8px -30px;
        }
  }  