.gradient-left{
position: absolute;
width: 487px;
height: 491px;
background: rgba(250,255,193,0.65 );
background: radial-gradient(farthest-corner at 50% 50%, 
rgba(250,255,193,0.65 ) 0%, 
#f4ff7d 100%);
left: 0;
top: 12%;
}
.gradient-right{
    position: absolute;
    width: 487px;
    height: 491px;
    background: rgba(250,255,193,0.65 );
    background: radial-gradient(farthest-corner at 50% 50%, 
    rgba(250,255,193,0.65 ) 0%, 
    #f4ff7d 100%);
    right: 0;
    bottom: 5%;
}